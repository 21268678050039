<template>
  <div class="container">
    <Breadcrumb />
    <h1>
      {{ $t("pageTitle.MergeUser") }}
    </h1>
    <el-row>
      <el-select
        :key="index"
        v-for="(selectUser, index) in selectedUsers"
        :value="selectUser.id"
        @change="userId => setUser({ id: userId }, index)"
        filterable
        placeholder="Select"
        style="width: 200px; margin: 0px 4px 4px 0px"
      >
        <el-option
          v-for="user in filterSelectedUser(users)"
          :key="user.id"
          :label="`${user.first_name} ${user.last_name} (${user.id})`"
          :value="user.id"
        >
        </el-option>
      </el-select>
      <el-button @click="addUser" type="primary">
        <i class="fas fa-plus" />
        Add Input
      </el-button>
    </el-row>
    <el-button @click="fetchUsersInformation" type="primary" size="small">
      <i class="fas fa-search" />
    </el-button>
    <hr />
    <h2>please choose which infomation you want to keep</h2>
    <div v-if="wantToMergeUsers[0] && wantToMergeUsers[0].basic_info">
      <div
        :key="columnsType"
        v-for="columnsType in ['basic_info', 'student', 'instructor']"
      >
        <hr class="separate-line" />
        <h3>{{ columnsType }}</h3>
        <el-table
          :data="mergeColumns.filter(({ type }) => type === columnsType)"
        >
          <el-table-column label="欄位名稱" prop="columnName" />
          <el-table-column
            :key="wantToMergeUser.id"
            v-for="wantToMergeUser in wantToMergeUsers"
            :label="
              `${wantToMergeUser.basic_info.first_name} ${wantToMergeUser.basic_info.last_name}`
            "
          >
            <template slot-scope="scope">
              <el-radio
                v-model="scope.row.username"
                :label="wantToMergeUser.basic_info.username"
              >
                {{
                  wantToMergeUser[scope.row.type]
                    ? wantToMergeUser[scope.row.type][scope.row.columnName]
                    : ""
                }}
              </el-radio>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-if="conflictClasses.length > 0">
        <hr class="separate-line" />
        <h3>班級考試</h3>
        <div v-for="(section, index) in conflictClasses" :key="index">
          <h4>{{ section.title }}</h4>
          <StudentTestScoreTable
            :section="section"
            @getSessions="fetchUsersInformation"
            :disableAvg="true"
            :disableEdit="true"
            :isMergeCheck="true"
            :mergeCheckDependent="mergeCheckDependent"
            @checkedConflictClassExam="checkedConflictClassExam"
          />
          <hr class="separate-line" />
        </div>
      </div>
    </div>
    <el-row>
      <el-button @click="mergeUsers" type="primary">
        Merge
      </el-button>
    </el-row>
  </div>
</template>

<script>
import courseApi from "@/apis/course";
import usersApi from "@/apis/users";
import profileApi from "@/apis/profile";
import Breadcrumb from "@/components/Breadcrumb";
import StudentTestScoreTable from "@/components/sessionClass/StudentTestScoreTable";

export default {
  metaInfo() {
    return {
      title: `${this.$t("pageTitle.MergeUser")} - Ivy-Way Academy`
    };
  },

  components: {
    Breadcrumb,
    StudentTestScoreTable
  },
  data() {
    return {
      selectedUsers: [{ id: null }, { id: null }],
      wantToMergeUsers: [],
      mergeColumns: [],
      mergeCheckDependent: {},
      users: [],
      conflictClasses: []
    };
  },
  async created() {
    this.users = await usersApi.getActiveUsers();
    this.initialState();
  },

  methods: {
    initialState() {
      this.wantToMergeUsers = [];
      this.selectedUsers = [{ id: null }, { id: null }];
      this.mergeColumns = [
        { type: "basic_info", columnName: "id" },
        { type: "basic_info", columnName: "username" },
        { type: "basic_info", columnName: "first_name" },
        { type: "basic_info", columnName: "last_name" },
        { type: "basic_info", columnName: "chinese_name" },
        { type: "basic_info", columnName: "gender" },
        { type: "basic_info", columnName: "is_active" },
        { type: "basic_info", columnName: "phone_country_code" },
        { type: "basic_info", columnName: "phone" },
        { type: "basic_info", columnName: "home_phone_country_code" },
        { type: "basic_info", columnName: "home_phone" },
        { type: "basic_info", columnName: "photo_url" },
        { type: "basic_info", columnName: "wechat_id" },
        { type: "basic_info", columnName: "line_id" },
        { type: "basic_info", columnName: "facebook_id" },
        { type: "basic_info", columnName: "skype_id" },
        { type: "basic_info", columnName: "lang" },
        { type: "basic_info", columnName: "name" },
        { type: "basic_info", columnName: "email" },
        { type: "basic_info", columnName: "password" },
        { type: "basic_info", columnName: "timezone" },
        { type: "basic_info", columnName: "default_currency" },
        { type: "basic_info", columnName: "address" },
        { type: "student", columnName: "city" },
        { type: "student", columnName: "state" },
        { type: "student", columnName: "country" },
        { type: "student", columnName: "living" },
        { type: "student", columnName: "middle_school" },
        { type: "student", columnName: "middle_school_graduation_year" },
        { type: "student", columnName: "high_school" },
        { type: "student", columnName: "high_school_graduation_year" },
        { type: "student", columnName: "essay_doc_type" },
        { type: "student", columnName: "counseling_schools_type" },
        { type: "student", columnName: "college" },
        { type: "student", columnName: "college_major" },
        { type: "student", columnName: "college_graduation_year" },
        { type: "student", columnName: "taken_class_where" },
        { type: "student", columnName: "taken_class_comments" },
        { type: "student", columnName: "lang_pref" },
        { type: "student", columnName: "will_mandarin_help" },
        { type: "student", columnName: "know_us" },
        { type: "student", columnName: "recommendation_info" },
        { type: "student", columnName: "is_student_referral" },
        { type: "student", columnName: "referrer" },
        { type: "student", columnName: "payment_method" },
        { type: "student", columnName: "currency" },
        { type: "student", columnName: "admin_notes" },
        { type: "instructor", columnName: "lang" },
        { type: "instructor", columnName: "application_id" },
        { type: "instructor", columnName: "display_name" },
        { type: "instructor", columnName: "official_name" },
        { type: "instructor", columnName: "ssn" },
        { type: "instructor", columnName: "dob" },
        { type: "instructor", columnName: "bio" },
        { type: "instructor", columnName: "show_bio" },
        { type: "instructor", columnName: "address1" },
        { type: "instructor", columnName: "address2" },
        { type: "instructor", columnName: "city" },
        { type: "instructor", columnName: "state" },
        { type: "instructor", columnName: "country" },
        { type: "instructor", columnName: "zip_code" },
        { type: "instructor", columnName: "college" },
        { type: "instructor", columnName: "college_graduation_year" },
        { type: "instructor", columnName: "college_major" },
        { type: "instructor", columnName: "subjects" },
        { type: "instructor", columnName: "current_year" },
        { type: "instructor", columnName: "notes" },
        { type: "instructor", columnName: "hours_available" },
        { type: "instructor", columnName: "payment" },
        { type: "instructor", columnName: "class_area" },
        { type: "instructor", columnName: "mandarin_ability" },
        { type: "instructor", columnName: "other_test_scores" },
        { type: "instructor", columnName: "tutoring_bio" },
        { type: "instructor", columnName: "counseling_bio" },
        { type: "instructor", columnName: "in_person_salary" },
        { type: "instructor", columnName: "in_person_currency" },
        { type: "instructor", columnName: "online_salary" },
        { type: "instructor", columnName: "online_currency" }
      ];
    },
    filterSelectedUser(users) {
      return this.users.filter(
        user =>
          !this.selectedUsers.some(selectUser => selectUser.id === user.id)
      );
    },
    addUser() {
      this.isFinishedSearch = false;
      this.selectedUsers = [...this.selectedUsers, { id: null }];
    },
    setUser(payload, targetIndex) {
      this.selectedUsers = this.selectedUsers.map((user, index) => {
        if (index !== targetIndex) return user;
        return { ...user, ...payload };
      });
    },
    async fetchUsersInformation() {
      this.isFinishedSearch = true;
      this.wantToMergeUsers = this.selectedUsers = this.selectedUsers.filter(
        selectedUser => selectedUser.id
      );
      this.wantToMergeUsers.forEach(async ({ id }, index) => {
        const user = await profileApi.getUserData(id);
        this.wantToMergeUsers[index] = {
          ...this.wantToMergeUsers[index],
          ...user
        };
        this.wantToMergeUsers = [...this.wantToMergeUsers];

        if (index !== 0) return;
        this.mergeColumns = this.mergeColumns.map(mergeColumn => ({
          ...mergeColumn,
          username: user.basic_info.username
        }));
      });
      this.getConflictMfMergeUsers();
    },
    findTargetUser(username) {
      return this.wantToMergeUsers.find(
        ({ basic_info }) => basic_info.username === username
      );
    },
    async getConflictMfMergeUsers() {
      const { conflict_classes } = await usersApi.conflictOfMergingUsers({
        main_user_id: this.wantToMergeUsers[0].id,
        merging_user_ids: this.wantToMergeUsers.slice(1).map(user => user.id)
      });
      const wantToMergeUserIds = this.wantToMergeUsers.map(user => user.id);
      const getClassPromises = conflict_classes.map(async ({ class_id }) => {
        const { session_class } = await courseApi.fetchSessionClass(class_id);
        return {
          ...session_class,
          student_class_tickets: session_class.student_class_tickets.filter(
            ({ student_user_id }) =>
              wantToMergeUserIds.includes(student_user_id)
          )
        };
      });
      this.conflictClasses = await Promise.all(getClassPromises);
      this.conflictClasses.forEach(conflictClass => {
        const mainStudentClassTicket = conflictClass.student_class_tickets.find(
          ({ student_user_id }) =>
            this.wantToMergeUsers[0].id === student_user_id
        );
        for (const exam in mainStudentClassTicket.student_class_ticket_exams) {
          this.mergeCheckDependent = {
            ...this.mergeCheckDependent,
            [conflictClass.id]: {
              ...this.mergeCheckDependent[conflictClass.id],
              [exam]: mainStudentClassTicket.student_class_ticket_exams[exam].id
            }
          };
        }
      });
    },
    checkedConflictClassExam(classId, examName, id) {
      this.mergeCheckDependent = {
        ...this.mergeCheckDependent,
        [classId]: {
          ...this.mergeCheckDependent[classId],
          [examName]: id
        }
      };
    },
    async mergeUsers() {
      if (!this.isFinishedSearch) {
        this.$message.error(this.$t("選擇使用者後請先搜尋再合併！"));
        return;
      }

      const merge = {
        main_user_id: this.wantToMergeUsers[0].id,
        merging_user_ids: this.wantToMergeUsers.slice(1).map(user => user.id)
      };

      this.mergeColumns.forEach(mergeColumn => {
        const targetUser = this.findTargetUser(mergeColumn.username);
        if (
          targetUser.basic_info.all_roles.includes(mergeColumn.type) ||
          mergeColumn.type === "basic_info"
        ) {
          merge[mergeColumn.columnName] =
            targetUser[mergeColumn.type][mergeColumn.columnName];
        }
      });

      if (this.conflictClasses.length > 0) {
        for (const conflictClass in this.mergeCheckDependent) {
          merge.conflictClass = [
            ...(merge.conflictClass || []),
            {
              class_id: conflictClass,
              selected_student_class_ticket_exam_ids: Object.keys(
                this.mergeCheckDependent[conflictClass]
              ).map(
                examName => this.mergeCheckDependent[conflictClass][examName]
              )
            }
          ];
        }
      }

      try {
        await usersApi.mergeUsers(merge);
        this.initialState();
        this.$message.success(this.$t("message.update_success"));
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    }
  }
};
</script>

<style scoped></style>
